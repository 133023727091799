<template>
  <div class="select-projects">
    <step-summary
      :label="t('integration')"
      :previous-answers="[platformName, selectedIntegration?.name ?? '']"
    />
    <step-summary :label="t('automation')" :previous-answers="[automationName ?? '']" />
    <step-summary
      :label="t('trigger')"
      :previous-answers="[
        t(`${snakeCase(selectedTrigger ?? '')}.title`),
        isFormIntegration ? formImpactPillContent : '',
      ]"
    />

    <v-form v-model="isFormValid">
      <section class="select-project-wrapper">
        <template v-if="selectedTrigger !== 'perSelectedAnswer'">
          <div class="label-wrapper">
            <p class="label choose">
              {{ t(`${snakeCase(selectedTrigger ?? '')}.impact_header_title`) }}
            </p>
            <p class="description">
              {{ t(`${snakeCase(selectedTrigger ?? '')}.impact_header_description`) }}
            </p>
          </div>
          <ImpactSettings
            v-if="selectedTrigger !== 'offsetPerProduct'"
            v-model="impactSetting"
            :single-selection="isPercentageBasedImpact"
            @onValidity="setImpactSettingValidity"
          />
        </template>
        <template v-else>
          <div class="label-wrapper with-help-text">
            <p class="label">
              {{ t('impact_per_question_title') }}
            </p>
          </div>
          <p class="help-text">
            {{ t('impact_per_question_description') }}
          </p>
          <ImpactSettingsPerQuestion
            v-if="formQuestion"
            :question-data="formQuestion"
            @save="onImpactSettingsPerQuestionSave"
          />
        </template>
        <template v-if="selectedTrigger === 'offsetPerProduct'">
          <ProductTable
            v-show="showProductsTable"
            ref="productTable"
            :integration-id="selectedIntegration?.id"
            :selected-products="selectedProducts"
            @select-items="(items) => (selectedProducts = items)"
            @save="onImpactSettingsSave"
          />
        </template>
      </section>
      <section v-if="isAdditionalSettingNeeded" class="additional-impact-setting">
        <div class="label-wrapper">
          <p class="label choose">
            {{ t(`${snakeCase(selectedTrigger ?? '')}.label`) }}
          </p>
          <p class="description">{{ t(`${snakeCase(selectedTrigger ?? '')}.tooltip`) }}</p>
        </div>
        <div class="input-wrapper">
          <template v-if="showCurrencySettingSelect">
            <v-select
              v-model="additionalImpactSetting.currencyCode"
              :items="availableCurrencies"
              variant="outlined"
              color="green"
              item-color="green"
              class="dropdown-select"
              hide-details
            />
          </template>

          <gs-input
            v-if="isPercentageBasedImpact"
            v-model.number="additionalImpactSetting.percentage"
            :label="additionalSettingInputLabel"
            type="number"
            min="0"
            class="additional-impact-setting-input"
            :rules="[rules.positiveInteger, rules.maxValue(100), rules.required]"
          />

          <gs-input
            v-else
            v-model.number="additionalImpactSetting.amount"
            :label="additionalSettingInputLabel"
            type="number"
            min="0"
            class="additional-impact-setting-input"
            :rules="[rules.positiveInteger, rules.required]"
          />
        </div>
      </section>
      <section class="schedule-picker">
        <div class="label-wrapper with-help-text mb-6">
          <p class="label choose">
            {{ t('schedule') }}
          </p>
          <p class="description">{{ t('schedule_tooltip') }}</p>
        </div>
        <DateTimePicker
          v-model="schedule.startDate"
          :min-value="initialDate"
          class="mb-6"
          :clearable="false"
          :max-width="450"
        />

        <p class="mb-1">End date</p>
        <v-checkbox
          id="checkbox-datetime"
          v-model="isEndDateVisible"
          name="checkbox-datetime"
          inline
          hide-details
          label="Set an end date"
        />

        <DateTimePicker
          v-if="isEndDateVisible"
          :model-value="schedule.endDate ?? new Date(schedule.startDate).toISOString()"
          @update:model-value="(v) => (schedule.endDate = v)"
          :min-value="new Date(schedule.startDate).toISOString()"
          :error="!isScheduleValid"
          :max-width="450"
          @focus="isScheduleValid = true"
          :clearable="false"
        />
      </section>
      <section v-if="selectedTrigger !== 'offsetPerProduct'" class="totals-wrapper">
        <p v-if="!!cartText" class="cart-items-text">
          {{ cartText }}
        </p>
        <p v-if="isTotalTextNeeded" class="total-text">
          {{
            t(`${snakeCase(selectedTrigger ?? '')}.total`, {
              spend_level: `${getCurrencySymbol(additionalImpactSetting.currencyCode || 'USD')}${
                additionalImpactSetting.amount || additionalImpactSetting.percentage || 0
              }`,
              price: `${userCurrencySymbol}${total} ${getUserCurrencyCode}`,
            })
          }}
        </p>
        <button
          v-if="isTotalTextNeeded && isFreeSubscription"
          class="total-text paid-plan"
          @click.prevent="openPriceComparisonPopup"
        >
          {{
            t(`${snakeCase(selectedTrigger ?? '')}.total_paid_plan`, {
              spend_level: `${getCurrencySymbol(additionalImpactSetting.currencyCode || 'USD')}${
                additionalImpactSetting.amount || additionalImpactSetting.percentage || 0
              }`,
              price: `${userCurrencySymbol}${totalPaidPlan} ${getUserCurrencyCode}`,
            })
          }}
        </button>
        <p v-if="showZeroValueTriggerDisclaimer" class="zero-value-disclaimer">
          {{
            t('zero_value_trigger_disclaimer_part_1', {
              currency_symbol: getCurrencySymbol(additionalImpactSetting.currencyCode || 'USD'),
            })
          }}
          <a href="" @click.prevent="onChangeTriggerToSpendThreshold">
            {{ t('spend_threshold_trigger') }}</a
          >
          {{ t('zero_value_trigger_disclaimer_part_2') }}
        </p>
      </section>
      <div
        v-if="selectedTrigger === 'perSelectedAnswer'"
        class="per-selected-answer-notification-wrapper"
      >
        <PerSelectedAnswerPostPurchaseEmailNotification />
      </div>
      <div class="form-controls">
        <gs-button
          capitalized
          muted
          outlined
          type="secondary-v2"
          size="large"
          class="form-control"
          :uppercased="false"
          icon="mdi-chevron-left"
          @click="$emit('back')"
        >
          {{ $t('CommonUi.back') }}
        </gs-button>
        <gs-button
          full-width
          capitalized
          :uppercased="false"
          type="primary-v2"
          size="large"
          class="form-control"
          :loading="loading"
          :disabled="isButtonDisabled || !isFormValid || !isImpactSettingValid || loading"
          @click.prevent="handleSaveClick"
        >
          {{ saveButtonText }}
        </gs-button>
      </div>
    </v-form>
  </div>
</template>

<script lang="ts">
import type { CurrencyCode, ShopifyOrderOrigin } from '@/helpers/interfaces'
import { Utils } from '@/helpers/mixins/utilsMixin'
import ImpactSettings from '@/components/onboarding/ImpactSettings.vue'
import type { ProjectId } from '@/helpers/constants'
import { IMPACT_LOWER_PRICE_LIMIT } from '@/helpers/constants'
import { includes } from '@/helpers/parsers'
import { currencySymbols } from '@/helpers/pricing'
import { Decimal } from 'decimal.js'
import type { TranslateResult } from 'vue-i18n'
import GsInput from '@/components/form/gs-input.vue'
import type { CreateAutomationRequestBody } from '@api/index'
import type { Account } from '@/store'
import ProductTable from '@/components/onboarding/ProductTable.vue'
import DateTimePicker from '@/components/common/DateTimePicker.vue'
import type { Notification } from '@/store/notification'
import { RulesMixin } from '@/helpers/mixins/RulesMixin.vue'
import type {
  AutomationModifier,
  AutomationOffset,
  AutomationTrigger,
  IntegrationTableData,
  IntegrationOption,
} from '@/store/integrations'
import { FORM_INTEGRATION_SOURCES } from '@/helpers/constants'
import { isDemoEnvironment } from '@/helpers/constants'
import { IntegrationsMixin } from '@/helpers/mixins/integrationsMixin'
import type { SelectedIntegration } from '@/components/onboarding/SelectIntegration.vue'
import type { SubscriptionItem } from '@/store/subscriptions'
import ImpactSettingsPerQuestion from '@/components/onboarding/ImpactSettingsPerQuestion.vue'
import StepSummary from '@/components/form/StepSummary.vue'
import type {
  CreateFormQuestionRequestPayload,
  FormAnswer,
  FormQuestion,
} from '@/store/form-question'
import PerSelectedAnswerPostPurchaseEmailNotification from '@/components/integration/PerSelectedAnswerPostPurchaseEmailNotification.vue'
import type { Project } from '@/store/projects'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SelectProjects',
  emits: ['revert-trigger-selection', 'back'],
  components: {
    StepSummary,
    DateTimePicker,
    ProductTable,
    GsInput,
    ImpactSettings,
    ImpactSettingsPerQuestion,
    PerSelectedAnswerPostPurchaseEmailNotification,
  },
  mixins: [Utils, RulesMixin, IntegrationsMixin],
  data() {
    return {
      selectedProducts: [],
      availableCurrencies: [],
      additionalImpactSetting: {
        amount: null,
        currencyCode: 'USD',
        percentage: null,
      },
      impactSetting: [],
      schedule: {
        startDate: new Date(Date.now()).toISOString(),
        endDate: undefined,
      },
      isScheduleValid: true,
      showProductsTable: true,
      loading: false,
      isEndDateVisible: false,
      isFormValid: true,
      isImpactSettingValid: true,
      initialDate: new Date(Date.now()).toISOString(),
    } as {
      selectedProducts: string[]
      availableCurrencies: { title: string; value: CurrencyCode }[]
      additionalImpactSetting: {
        amount: number | null
        currencyCode?: CurrencyCode
        percentage?: number | null
      }
      impactSetting: AutomationOffset[]
      schedule: {
        startDate: string
        endDate?: string
      }
      isScheduleValid: boolean
      showProductsTable: boolean
      loading: boolean
      isEndDateVisible: boolean
      isFormValid: boolean
      isImpactSettingValid: boolean
      initialDate: string
    }
  },
  computed: {
    total(): number {
      let total = 0
      this.impactSetting.forEach(({ amount, projectId }) => {
        if (!projectId) return
        total = new Decimal(this.getProjectPriceById(projectId as string))
          .times(amount || 0)
          .add(total)
          .toNumber()
      })
      return total
    },
    totalPaidPlan(): number {
      let total = 0
      this.impactSetting.forEach(({ amount, projectId }) => {
        total = new Decimal(this.getPaidProjectPriceById(projectId as string))
          .times(amount || 0)
          .add(total)
          .toNumber()
      })
      return new Decimal(total).toNumber()
    },
    cartText(): string | TranslateResult {
      // if there is no value set
      if (this.impactSetting.every(({ amount }) => !amount)) {
        return ''
      } else if (
        (this.selectedTrigger === 'offsetByStoreRevenue' ||
          this.selectedTrigger === 'offsetBySpend' ||
          this.selectedTrigger === 'bySpendOfPaidInvoice' ||
          this.selectedTrigger === 'byTieredSpendOfPaidInvoice') &&
        !this.additionalImpactSetting.amount
      ) {
        return ''
      } else if (this.isPercentageBasedImpact && !this.additionalImpactSetting.percentage) {
        return ''
      } else {
        let impactText = ''
        // filter the object properties to the properties with values
        const impactSettingWithValues: AutomationOffset[] = this.impactSetting.filter(
          ({ amount }) => amount,
        )
        const accumulatedImpactSettingsByType = impactSettingWithValues.reduce(
          (acc, { amount, type }) => {
            const itemByType = acc.find((item) => item.type === type)
            const amountToAdd = amount || 0
            if (!itemByType) {
              acc.push({ type, amount: amountToAdd })
            } else {
              itemByType.amount = itemByType.amount ? itemByType.amount + amountToAdd : amountToAdd
            }
            return acc
          },
          [] as AutomationOffset[],
        )
        const lastItemIndex = accumulatedImpactSettingsByType.length - 1
        accumulatedImpactSettingsByType.forEach(({ amount, type }, index) => {
          if (amount && !index) {
            impactText += this.tc(`${this.snakeCase(this.selectedTrigger ?? '')}.${type}`, amount)
          } else if (amount && lastItemIndex === 1) {
            impactText +=
              ', and ' + this.tc(`${this.snakeCase(this.selectedTrigger ?? '')}.${type}`, amount)
          } else if (amount && lastItemIndex > 1)
            impactText +=
              (index > 1 ? ', and ' : ', ') +
              this.tc(`${this.snakeCase(this.selectedTrigger ?? '')}.${type}`, amount)
        })
        const textValues = { amount: impactText, type: '' }
        // in case there are additional impact settings set up
        if (this.additionalImpactSetting.amount && this.additionalImpactSetting.currencyCode) {
          textValues.type =
            this.getCurrencySymbol(this.additionalImpactSetting.currencyCode) +
            this.additionalImpactSetting.amount
        } else if (this.additionalImpactSetting.percentage) {
          textValues.type = this.additionalImpactSetting.percentage + '%'
        }

        return this.capitalizeFirstLetter(
          this.t(`${this.snakeCase(this.selectedTrigger ?? '')}.text`, textValues),
        )
      }
    },
    isAdditionalSettingNeeded(): boolean {
      return (
        this.selectedTrigger === 'offsetByPercentageOrder' ||
        this.selectedTrigger === 'offsetBySpend' ||
        this.selectedTrigger === 'offsetByStoreRevenue' ||
        this.selectedTrigger === 'byPercentageOfPaidInvoice' ||
        this.selectedTrigger === 'bySpendOfPaidInvoice' ||
        this.selectedTrigger === 'byTieredSpendOfPaidInvoice'
      )
    },
    isPercentageBasedImpact(): boolean {
      return (
        this.selectedTrigger === 'offsetByPercentageOrder' ||
        this.selectedTrigger === 'byPercentageOfPaidInvoice'
      )
    },
    showCurrencySettingSelect(): boolean {
      return (
        this.selectedTrigger === 'offsetByStoreRevenue' ||
        this.selectedTrigger === 'offsetBySpend' ||
        this.selectedTrigger === 'byTieredSpendOfPaidInvoice' ||
        this.selectedTrigger === 'bySpendOfPaidInvoice'
      )
    },
    additionalSettingInputLabel(): string {
      switch (this.selectedTrigger) {
        case 'offsetByPercentageOrder':
        case 'byPercentageOfPaidInvoice':
          return '%'
        default:
          return 'Amount'
      }
    },
    showZeroValueTriggerDisclaimer(): boolean {
      return (
        this.selectedTrigger === 'perPaidInvoice' &&
        this.triggersByPlatform.includes('bySpendOfPaidInvoice')
      )
    },
    isTotalTextNeeded(): boolean {
      return (
        this.selectedTrigger !== 'offsetByPercentageOrder' &&
        this.selectedTrigger !== 'byPercentageOfPaidInvoice'
      )
    },
    isButtonDisabled(): boolean {
      if (this.isAdditionalSettingNeeded) {
        return (
          !this.total ||
          (!this.additionalImpactSetting.amount && !this.additionalImpactSetting.percentage)
        )
      } else {
        return this.total < IMPACT_LOWER_PRICE_LIMIT
      }
    },
    saveButtonText(): TranslateResult {
      if (!this.isAdditionalSettingNeeded && this.total < IMPACT_LOWER_PRICE_LIMIT) {
        return this.t('min_limit', { limit: this.userCurrencySymbol + IMPACT_LOWER_PRICE_LIMIT })
      } else {
        return this.t('save')
      }
    },
    platformName(): string {
      return this.selectedIntegration?.platform
        ? this.getIntegrationPlatformName(this.selectedIntegration.platform)
        : ''
    },
    isFreeSubscription(): boolean {
      return this.getActiveSubscriptionItem?.product === 'freeBusiness'
    },
    isFormIntegration(): boolean {
      return includes(FORM_INTEGRATION_SOURCES, this.selectedIntegration?.platform)
    },
    formImpactPillContent(): TranslateResult | string {
      return this.formImpactSource === 'all'
        ? this.t(`${this.snakeCase(this.selectedTrigger ?? '')}.all`)
        : this.formImpactSource
    },
    formQuestion(): FormQuestion {
      return this.$store.getters['getFormQuestion']
    },
    account(): Account {
      return this.$store.getters['getAccount']
    },
    userCurrencySymbol(): string {
      return this.$store.getters['getUserCurrencySymbol']
    },
    getActiveSubscriptionItem(): SubscriptionItem {
      return this.$store.getters['getActiveSubscriptionItem']
    },
    getPaidProjectPriceById(): (projectId: string) => number {
      return this.$store.getters['getPaidProjectPriceById']
    },
    projects(): Project[] {
      return this.$store.getters['getAppProjects']
    },
    getEmailSyncAlertDismissed(): boolean {
      return this.$store.getters['getEmailSyncAlertDismissed']
    },
    integrationOptionList(): IntegrationOption[] {
      return this.$store.getters['getIntegrationOptionList']
    },
    selectedIntegrationOption(): IntegrationOption | undefined {
      return this.integrationOptionList.find((option) => {
        if (isDemoEnvironment) {
          return (
            `${option.name.toLowerCase()} demo` === this.selectedIntegration?.name.toLowerCase()
          )
        } else {
          return option.name.toLowerCase() === this.selectedIntegration?.name.toLowerCase()
        }
      })
    },
    triggersByPlatform(): AutomationTrigger[] {
      return this.$store.getters['getAvailableAutomationTriggersByPlatform'](
        this.selectedIntegration?.platform,
      )
    },
    integrationList(): IntegrationTableData[] {
      return this.getIntegrationsForTable()
    },
    getIntegrationsForTable(): () => IntegrationTableData[] {
      return this.$store.getters['getIntegrationsForTable']
    },
    hasSyncedIntegrations(): number {
      return this.integrationList.filter(
        (integration) => integration.writeDirection?.status?.status === 'active',
      ).length
    },
  },
  created() {
    this.impactSetting = (this.projects || []).map((project) => ({
      type: project.type,
      projectId: project.projectId as ProjectId,
      amount: null,
    }))
    this.setAvailableCurrencies()
    this.resetFormQuestion()
  },
  methods: {
    t(key: string, params?: { [key: string]: string | number }) {
      return this.$t(`SelectProjects.${key}`, params ?? {})
    },
    tc(key: string, count: number) {
      return this.$t(`SelectProjects.${key}`, count)
    },
    getProjectPriceById(projectId: string): number {
      return this.$store.getters['getProjectPriceById'](projectId, this.isFreeSubscription)
    },
    setAvailableCurrencies() {
      this.availableCurrencies = Object.entries(currencySymbols).map(([key, value]) => {
        return {
          title: value,
          value: key as CurrencyCode,
        }
      })
    },
    setImpactSettingValidity(payload: boolean) {
      this.isImpactSettingValid = payload
    },
    onChangeTriggerToSpendThreshold() {
      if (!this.triggersByPlatform.includes('bySpendOfPaidInvoice')) return
      this.$emit('revert-trigger-selection', 'bySpendOfPaidInvoice')
    },
    async handleSaveClick() {
      this.loading = true
      if (this.selectedTrigger === 'perSelectedAnswer') {
        await this.saveFormQuestion()
      }
      const filteredImpactSetting = this.impactSetting.filter(({ amount }) => amount)
      const createAutomationRequestBody = {
        name: this.automationName,
        schedule: this.schedule,
        trigger:
          this.selectedTrigger === 'offsetAllProducts' ? 'offsetPerProduct' : this.selectedTrigger,
        offsets: filteredImpactSetting,
      } as CreateAutomationRequestBody

      if (this.isAdditionalSettingNeeded) {
        createAutomationRequestBody.modifiers = []
        const modifierAmount: number =
          this.additionalImpactSetting.amount || this.additionalImpactSetting.percentage || 0
        createAutomationRequestBody.modifiers = this.getModifierTypeByTrigger(
          modifierAmount,
          this.additionalImpactSetting.currencyCode,
        )
      }

      if (this.selectedIntegration?.platform === 'shopify') {
        const shopifyOrderOrigin: AutomationModifier = {
          type: 'orderOrigin',
          stringValue: this.shopifyOrderOrigin,
        }
        createAutomationRequestBody.modifiers?.length
          ? createAutomationRequestBody.modifiers.push(shopifyOrderOrigin)
          : (createAutomationRequestBody.modifiers = [shopifyOrderOrigin])
      }
      if (this.selectedTrigger === 'offsetAllProducts') {
        const globalOffsetPerProductModifier: AutomationModifier = {
          type: 'globalOffsetPerProduct',
          booleanValue: true,
        }
        createAutomationRequestBody.modifiers?.length
          ? createAutomationRequestBody.modifiers.push(globalOffsetPerProductModifier)
          : (createAutomationRequestBody.modifiers = [globalOffsetPerProductModifier])
      }
      if (this.selectedProducts.length) {
        createAutomationRequestBody.ids = []
      }
      if (this.isFormIntegration) {
        createAutomationRequestBody.ids =
          this.formImpactSource === 'all' ? [] : [this.formImpactSource]

        const allFormsModifier: AutomationModifier = {
          type: 'allForms',
          booleanValue: this.formImpactSource === 'all',
        }
        createAutomationRequestBody.modifiers?.length
          ? createAutomationRequestBody.modifiers.push(allFormsModifier)
          : (createAutomationRequestBody.modifiers = [allFormsModifier])
      }
      try {
        await this.createAutomation({
          integrationId: this.selectedIntegration?.id ?? '',
          createAutomationRequestBody,
        })

        if (this.onSaveNavigation) {
          await this.onSaveNavigation()
        } else {
          await this.setOnboardingSkipped()

          if (this.selectedIntegrationOption?.writeDirection?.isAvailable) {
            this.setSelectedIntegrationForDataSync(this.selectedIntegration)
            return await this.$router.push({
              name: 'EmailDataSync',
              query: this.$route.path === '/onboarding' ? { from: 'onboarding' } : {},
            })
          }

          if (this.$route.path === '/onboarding') {
            if (this.getEmailSyncAlertDismissed || this.hasSyncedIntegrations) {
              await this.$router.push({
                path: '/',
                query: { from: 'onboarding' },
              })
            } else {
              await this.$router.push({
                name: 'EmailIntegrationSyncAlert',
                query: { from: 'onboarding' },
              })
            }
          } else {
            if (this.getEmailSyncAlertDismissed || this.hasSyncedIntegrations) {
              await this.$router.push('/add-impact/manage-integrations-automations/automations')
            } else {
              await this.$router.push({
                name: 'EmailIntegrationSyncAlert',
              })
            }
          }
        }
      } catch (e) {
        console.error(e)
        this.$store.dispatch('notification/notify', {
          text: this.$t('CommonUi.error_generic'),
          isError: true,
          isClosable: true,
          buttonText: 'close',
        } as Notification)
        this.loading = false
      }
    },
    async saveFormQuestion() {
      const originalQuestionData = this.deepCopy(this.formQuestion) as { answers: FormAnswer[] }
      const { question, answers } = this.formQuestion

      await this.createFormQuestion({ createFormQuestionRequestPayload: { question, answers } })
      // update source (answer) ids on impactSetting as backend returns new answer ids
      // create an oldId -> newId map
      const sourceIdsMap = originalQuestionData.answers.reduce((acc, { answer, id: oldId }) => {
        const newId = this.formQuestion.answers.find(
          (answerData) => answerData.answer === answer,
        )?.id
        if (!newId) throw new Error(`Answer not found: ${answer}`)
        acc[oldId] = newId
        return acc
      }, {} as { [key: string]: string })
      // update source ids on impactSetting
      this.impactSetting.forEach(({ source }) => {
        if (source) source.ids = [sourceIdsMap[source.ids[0]]]
      })
    },
    getModifierTypeByTrigger(value: number, currency?: string): AutomationModifier[] {
      switch (this.selectedTrigger) {
        case 'offsetByStoreRevenue':
        case 'byTieredSpendOfPaidInvoice':
          return [
            { type: 'threshold', numberValue: value },
            { type: 'currencyCode', stringValue: currency },
          ]
        case 'offsetBySpend':
        case 'bySpendOfPaidInvoice':
          return [
            { type: 'spendAmount', numberValue: value },
            { type: 'currencyCode', stringValue: currency },
          ]
        case 'offsetByPercentageOrder':
        case 'byPercentageOfPaidInvoice':
          return [{ type: 'percentage', numberValue: value }]
        default:
          throw new Error('Invalid trigger type')
      }
    },
    onImpactSettingsPerQuestionSave(updatedData: FormQuestion) {
      this.impactSetting = updatedData.answers.flatMap(({ id, offsets }) =>
        offsets.map((offset) => ({
          ...offset,
          source: {
            type: 'answer',
            ids: [id],
          },
        })),
      )
      this.setFormQuestion({ formQuestion: updatedData })
    },
    onImpactSettingsSave(data) {
      this.impactSetting = data.flatMap(({ productId, offsets }) =>
        offsets.map((offset) => ({
          ...offset,
          source: {
            type: 'product',
            ids: [productId],
          },
        })),
      )
    },
    createAutomation(payload: {
      integrationId: string
      createAutomationRequestBody: CreateAutomationRequestBody
    }): Promise<void> {
      return this.$store.dispatch('createAutomation', payload)
    },
    setOnboardingSkipped(): Promise<void> {
      return this.$store.dispatch('setOnboardingSkipped')
    },
    openPriceComparisonPopup(): Promise<void> {
      return this.$store.dispatch('openPriceComparisonPopup')
    },
    setFormQuestion(payload: { formQuestion: FormQuestion }): Promise<void> {
      return this.$store.dispatch('setFormQuestion', payload)
    },
    createFormQuestion(payload: {
      createFormQuestionRequestPayload: CreateFormQuestionRequestPayload
    }): Promise<Promise<void>> {
      return this.$store.dispatch('createFormQuestion', payload)
    },
    resetFormQuestion(): Promise<void> {
      return this.$store.dispatch('resetFormQuestion')
    },
    setSelectedIntegrationForDataSync(
      integration: SelectedIntegration | IntegrationTableData | undefined,
    ) {
      return this.$store.commit('setSelectedIntegrationForDataSync', integration)
    },
  },
  props: {
    selectedTrigger: {
      type: String as PropType<AutomationTrigger>,
    },
    selectedIntegration: {
      type: Object as PropType<SelectedIntegration | IntegrationTableData>,
    },
    automationName: {
      type: String,
    },
    shopifyOrderOrigin: {
      default: 'all',
      type: String as PropType<ShopifyOrderOrigin>,
    },
    formImpactSource: {
      default: 'all',
      type: String as PropType<string | 'all'>,
    },
    onSaveNavigation: {
      type: Function as PropType<() => Promise<void>>,
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

section {
  padding: 15px 0;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    padding: 24px 0;
  }

  &:not(:last-of-type) {
    border-bottom: 2px solid var(--gray-light-CC);
  }
}

.integration-name-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.label-wrapper {
  margin-bottom: 32px;

  .label {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 8px;
  }

  .description {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 0;
  }
}

.label-wrapper.with-help-text {
  margin-bottom: 0;
}

.help-text {
  color: var(--gray);
  margin-bottom: 24px;
  max-width: 50ch;
}

.input-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.impact-settings-wrapper {
  padding: 0;
}

.select-project-wrapper {
  padding-bottom: 32px;
}

.dropdown-select {
  width: 100px;
  flex-grow: 0;
}

.additional-impact-setting-input {
  width: 200px;
  flex-grow: 0;
}

.totals-wrapper {
  text-align: right;
  min-height: 103px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .zero-value-disclaimer {
    padding: 4px;
    margin: 4px 0 8px;
    background-color: var(--ui-white);
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
  }
}

.cart-items-text {
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  color: var(--gray);
  margin-bottom: 10px;
}

.total-text {
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 12px;
}

.total-text.paid-plan {
  text-decoration: underline;
  color: var(--ui-green);
  cursor: pointer;
  text-align: right;
}

.checkbox-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
  padding-top: 20px;
}

.checkbox-wrapper label {
  margin: 0;
}

.per-selected-answer-notification-wrapper {
  padding: 0 15px;
}

.form-controls {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .section {
    padding: 24px 18px;
  }

  .button-wrapper {
    padding: 0 18px 24px;
  }

  .per-selected-answer-notification-wrapper {
    padding: 0 18px 24px;
  }
}
</style>
