import type { AccountType } from '@/helpers/interfaces'

export const REFERRAL_LINK_BUSINESS = 'https://www.getgreenspark.com/integrated-impact'
export const REFERRAL_LINK_PERSONAL = 'https://www.getgreenspark.com/personal'
export const IMPACT_LOWER_PRICE_LIMIT = 0.05
export const TAILORED_IMPACT_MINIMUM_LIMIT_WITHOUT_SUBSCRIPTION = 0.5
export const ALERT_TYPES = [
  'noSubscription',
  'fundingEnded',
  'fundingEnding',
  'unclaimedAllowance',
  'stoppedAt',
  'trialPeriod',
  'freeBusinessSubscription',
] as const
export type AlertType = (typeof ALERT_TYPES)[number]
export const PRIVACY_POLICY_URL =
  'https://app.privasee.io/privacy-portal/61a7587adcff740014657fbf?theme=light'
export const TERMS_CONDITIONS_URL = 'https://www.getgreenspark.com/utility/legal'
export const API_DOCUMENTATION_URL = 'https://greenspark.readme.io'
export const KNOWLEDGE_BASE_URL = 'https://help.getgreenspark.com'
export const SUPPORT_EMAIL_URL = 'mailto:support@getgreenspark.com?subject=Customer Support Enquiry'
export const ZAPIER_DOCUMENTATION_URL = 'https://help.getgreenspark.com/en/category/zapier-fpxps4/'
export const INTEGRATELY_DOCUMENTATION_URL =
  'https://help.getgreenspark.com/en/category/integrately-dwdq4v/'
export const TYPEFORM_DOCUMENTATION_URL =
  'https://help.getgreenspark.com/en/article/how-do-i-find-my-typeform-form-ids-1foj5zx/'
export const SURVEY_MONKEY_DOCUMENTATION_URL =
  'https://help.getgreenspark.com/en/article/how-to-intergrate-with-surveymonkey-qlevwk'
export const TYPEFORM_URL_TRIAL_STARTER = 'https://2tobd5b3dk5.typeform.com/to/Z3QRNS5I'
export const TYPEFORM_URL_TRIAL_GROWTH_PREMIUM = 'https://2tobd5b3dk5.typeform.com/to/eVY8ogGo'
export const TYPEFORM_URL_NON_TRIAL = 'https://2tobd5b3dk5.typeform.com/to/XQTqT7qd'
export const REBUY_INSTALLATION_GUIDE_URL =
  'https://help.getgreenspark.com/en/article/rebuy-smart-cart-app-installation-1e9wnum/'
export const REBUY_URL = 'https://www.rebuyengine.com/'
export const EARTH_LUNG_PROJECT_URL =
  'https://www.getgreenspark.com/projects/uplifting-impoverished-communities-through-mangrove-restoration'
export const NEW_PAID_BUSINESS_SUBSCRIPTION_MONTHLY_TYPES = [
  'starterBusiness',
  'growthBusiness',
  'premiumBusiness',
  'enterpriseBusiness',
  'freeBusiness',
] as const
export const NEW_PAID_BUSINESS_SUBSCRIPTION_YEARLY_TYPES = [
  'starterBusinessYearly',
  'growthBusinessYearly',
  'premiumBusinessYearly',
] as const
export const NEW_PAID_BUSINESS_SUBSCRIPTION_TYPES = [
  ...NEW_PAID_BUSINESS_SUBSCRIPTION_MONTHLY_TYPES,
  ...NEW_PAID_BUSINESS_SUBSCRIPTION_YEARLY_TYPES,
] as const

export const TECHNICAL_BUSINESS_SUBSCRIPTION_TYPES = ['businessLegacy'] as const
export const PAID_BUSINESS_SUBSCRIPTION_TYPES = [
  'ecommerce',
  'ECOMMERCE',
  ...NEW_PAID_BUSINESS_SUBSCRIPTION_TYPES,
] as const
export const PAID_BUSINESS_MONTHLY_SUBSCRIPTION_TYPES = [
  'ecommerce',
  'ECOMMERCE',
  ...NEW_PAID_BUSINESS_SUBSCRIPTION_MONTHLY_TYPES,
] as const
export type PaidBusinessMonthlySubscriptionType =
  (typeof PAID_BUSINESS_MONTHLY_SUBSCRIPTION_TYPES)[number]
export const FREE_BUSINESS_SUBSCRIPTION_TYPES = ['free', 'zero'] as const
export const BUSINESS_SUBSCRIPTION_TYPES = [
  ...FREE_BUSINESS_SUBSCRIPTION_TYPES,
  ...PAID_BUSINESS_SUBSCRIPTION_TYPES,
] as const
export const TECHNICAL_AND_BUSINESS_SUBSCRIPTION_TYPES = [
  ...BUSINESS_SUBSCRIPTION_TYPES,
  ...TECHNICAL_BUSINESS_SUBSCRIPTION_TYPES,
]
export type BusinessSubscriptionType = (typeof BUSINESS_SUBSCRIPTION_TYPES)[number]
export const REFERRAL_SOCIAL_PLATFORMS: ReferralSocialPlatform[] = [
  'facebook',
  'twitter',
  'linkedin',
  'email',
]
export type ReferralSocialPlatform = 'facebook' | 'twitter' | 'linkedin' | 'email'

export const PERSONAL_SUBSCRIPTION_TYPES = ['personal', 'plasticHeroPersonal', 'earthHero'] as const
export const FAMILY_SUBSCRIPTION_TYPES = [
  'positiveFamilyAdult',
  'positiveFamilyChild',
  'positiveFamilyPet',
  'earthPositiveFamilyAdult',
  'earthPositiveFamilyChild',
  'earthPositiveFamilyPet',
] as const
export type FamilySubscriptionType = (typeof FAMILY_SUBSCRIPTION_TYPES)[number]
export const TECHNICAL_AND_PAID_BUSINESS_SUBSCRIPTION_TYPES = [
  ...PAID_BUSINESS_SUBSCRIPTION_TYPES,
  ...TECHNICAL_BUSINESS_SUBSCRIPTION_TYPES,
]
export type PaidTechnicalAndBusinessSubscriptionType =
  (typeof TECHNICAL_AND_PAID_BUSINESS_SUBSCRIPTION_TYPES)[number]
export const INTEGRATION_CATEGORIES = [
  'all',
  'active',
  'store',
  'review',
  'email',
  'form',
  'integrator',
  'billing',
  'custom',
]
export type IntegrationCategory = (typeof INTEGRATION_CATEGORIES)[number]
export const FACEBOOK_TRACKING_OPTIONS = [
  'InitiateCheckout',
  'Subscribe',
  'ViewContent',
  'PageView',
] as const
export type FacebookTrackingOption = (typeof FACEBOOK_TRACKING_OPTIONS)[number]
export const LINKEDIN_TRACKING_OPTIONS = ['subscribe'] as const
export type LinkedinTrackingOption = (typeof LINKEDIN_TRACKING_OPTIONS)[number]
export const GOOGLE_TRACKING_OPTIONS = ['conversion'] as const
export type GoogleTagManagerTrackingOption = (typeof GOOGLE_TRACKING_OPTIONS)[number]
export const ACTIVE_CAMPAIGN_TRACKING_OPTIONS = ['subscribe'] as const
export type ActiveCampaignTrackingOption = (typeof ACTIVE_CAMPAIGN_TRACKING_OPTIONS)[number]
export const ACCOUNT_TYPES: AccountType[] = ['ecommerce', 'business', 'family', 'personal', 'admin']
export const GREENSPARK_SHOPIFY_MARKETPLACE_URL = 'https://apps.shopify.com/greenspark'
export const VERITREE_EARTHLUNGS_PROJECT_ID = 'c6e4b1bf-843e-4859-bc85-c3b764f507e6'
export const CARBON_POOL_PROJECT_ID = '7accc260-ab82-4c51-a959-e6736284c45a'
export const PLASTIC_BANK_PROJECT_ID = '596f61ee-7149-43ac-ba4e-efb90ee3bd47'
export const OREGON_TREES_PROJECT_ID = 'a0636f61-c9a4-4e42-ad34-26155f3dd827'
export const KELP_PROJECT_ID = '9deda055-cd6d-4ebe-b343-4c9b73ccf2d6'
export const PROJECT_IDS = [
  VERITREE_EARTHLUNGS_PROJECT_ID,
  CARBON_POOL_PROJECT_ID,
  PLASTIC_BANK_PROJECT_ID,
  OREGON_TREES_PROJECT_ID,
  KELP_PROJECT_ID,
] as const
export type ProjectId = (typeof PROJECT_IDS)[number]
export const PARTNER_PROJECTS: Array<string> = [
  PLASTIC_BANK_PROJECT_ID,
  CARBON_POOL_PROJECT_ID,
  VERITREE_EARTHLUNGS_PROJECT_ID,
]
export const FORM_INTEGRATION_SOURCES = ['typeform', 'surveymonkey'] as const

export const isDemoEnvironment = window.location.origin === 'https://demo.getgreenspark.com'
export const isDeployPreviewEnvironment = window.location.hostname.startsWith('deploy-preview')
export const isLocalEnvironment = window.location.hostname === 'localhost'
export const isDevelopmentEnvironment = window.location.hostname === 'dev.getmads.com'
export const isProductionEnvironment = window.location.hostname === 'app.getgreenspark.com'

export enum API_ERRORS {
  STORE_ALREADY_CONNECTED = 'STORE_ALREADY_CONNECTED',
}
