import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4ce9f272"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "widget-row-section" }
const _hoisted_2 = {
  key: 0,
  class: "widget-row"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_widgetRowItem = _resolveComponent("widgetRowItem")!
  const _component_WidgetDialog = _resolveComponent("WidgetDialog")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (_ctx.loaded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.brandColors, (color, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: _normalizeClass([
          'widget-wrapper',
          { 'cart-based': _ctx.widgetType?.trigger === 'offsetPerCurrentCart' },
        ])
            }, [
              _createVNode(_component_widgetRowItem, {
                color: color,
                "flavor-text": _ctx.widgetFlavorText,
                "widget-labels": _ctx.widgetLabels,
                "is-icon-visible": _ctx.widgetType?.trigger === 'offsetPerCurrentCart',
                "icon-types": _ctx.currentCartOffsetTypes
              }, null, 8, ["color", "flavor-text", "widget-labels", "is-icon-visible", "icon-types"]),
              _createVNode(_component_WidgetDialog, {
                mode: _ctx.dialogMode,
                type: _ctx.widgetType?.trigger,
                "widget-api-key": _ctx.widgetApiKey,
                color: color
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_widgetRowItem, {
                    color: color,
                    "flavor-text": _ctx.widgetFlavorText,
                    "widget-labels": _ctx.widgetLabels,
                    "is-used-in-dialog": true,
                    "is-icon-visible": _ctx.widgetType?.trigger === 'offsetPerCurrentCart',
                    "icon-types": _ctx.currentCartOffsetTypes
                  }, null, 8, ["color", "flavor-text", "widget-labels", "is-icon-visible", "icon-types"])
                ]),
                _: 2
              }, 1032, ["mode", "type", "widget-api-key", "color"])
            ], 2))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}