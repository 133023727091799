import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-56ad3634"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "add-integration-automation" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_existing_integration = _resolveComponent("select-existing-integration")!
  const _component_select_integration = _resolveComponent("select-integration")!
  const _component_full_width_panel = _resolveComponent("full-width-panel")!
  const _component_stepper_content = _resolveComponent("stepper-content")!
  const _component_custom_integration = _resolveComponent("custom-integration")!
  const _component_select_trigger = _resolveComponent("select-trigger")!
  const _component_CreateApiKey = _resolveComponent("CreateApiKey")!
  const _component_CopyApiKey = _resolveComponent("CopyApiKey")!
  const _component_select_projects = _resolveComponent("select-projects")!
  const _component_stepper = _resolveComponent("stepper")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("button", {
      class: "close-button",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.navigateBack && _ctx.navigateBack(...args)))
    }),
    _createVNode(_component_stepper, {
      modelValue: _ctx.activeStep,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.activeStep) = $event)),
      "max-value": 3,
      "show-logo": false
    }, {
      default: _withCtx(() => [
        _createVNode(_component_stepper_content, {
          "is-visible": _ctx.activeStep === 1
        }, {
          default: _withCtx(() => [
            _createVNode(_component_full_width_panel, {
              title: _ctx.stepOneTitle,
              description: _ctx.stepOneDescription
            }, {
              default: _withCtx(() => [
                (_ctx.isStepOneIsExistingIntegration)
                  ? (_openBlock(), _createBlock(_component_select_existing_integration, {
                      key: 0,
                      onIntegrationSelected: _ctx.integrationSelected
                    }, null, 8, ["onIntegrationSelected"]))
                  : (_openBlock(), _createBlock(_component_select_integration, {
                      key: 1,
                      onIntegrationSelected: _ctx.integrationSelected
                    }, null, 8, ["onIntegrationSelected"]))
              ]),
              _: 1
            }, 8, ["title", "description"])
          ]),
          _: 1
        }, 8, ["is-visible"]),
        _createVNode(_component_stepper_content, {
          "is-visible": _ctx.activeStep === 2
        }, {
          default: _withCtx(() => [
            _createVNode(_component_full_width_panel, {
              title: _ctx.stepTwoTitle,
              description: _ctx.stepTwoDescription
            }, {
              default: _withCtx(() => [
                (_ctx.isCustomIntegrationSelected)
                  ? (_openBlock(), _createBlock(_component_custom_integration, {
                      key: 0,
                      "custom-integration-settings": _ctx.selectedCustomIntegrationSettings,
                      type: _ctx.selectedCustomIntegrationType,
                      "image-url": _ctx.selectedCustomIntegrationSettings.imageUrl,
                      onBack: _ctx.stepBack,
                      onNext: _ctx.setCustomIntegrationSettings
                    }, null, 8, ["custom-integration-settings", "type", "image-url", "onBack", "onNext"]))
                  : (_openBlock(), _createBlock(_component_select_trigger, {
                      key: 1,
                      "selected-integration": _ctx.selectedIntegration,
                      onBack: _cache[1] || (_cache[1] = ($event: any) => (_ctx.activeStep = 1)),
                      name: _ctx.selectedTrigger.automationName,
                      "onUpdate:name": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedTrigger.automationName) = $event)),
                      "active-trigger": _ctx.selectedTrigger.trigger,
                      "onUpdate:activeTrigger": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedTrigger.trigger) = $event)),
                      "shopify-order-origin": _ctx.selectedTrigger.shopifyOrderOrigin,
                      "onUpdate:shopifyOrderOrigin": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectedTrigger.shopifyOrderOrigin) = $event)),
                      "form-impact-source": _ctx.selectedTrigger.formImpactSource,
                      "onUpdate:formImpactSource": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.selectedTrigger.formImpactSource) = $event)),
                      onSubmit: _ctx.submitTriggerSelection
                    }, null, 8, ["selected-integration", "name", "active-trigger", "shopify-order-origin", "form-impact-source", "onSubmit"]))
              ]),
              _: 1
            }, 8, ["title", "description"])
          ]),
          _: 1
        }, 8, ["is-visible"]),
        _createVNode(_component_stepper_content, {
          "is-visible": _ctx.activeStep === 3
        }, {
          default: _withCtx(() => [
            _createVNode(_component_full_width_panel, {
              title: _ctx.stepThreeTitle,
              description: _ctx.stepThreeDescription
            }, {
              default: _withCtx(() => [
                (_ctx.isCustomIntegrationSelected && !_ctx.apiKey)
                  ? (_openBlock(), _createBlock(_component_CreateApiKey, {
                      key: 0,
                      "custom-integration-setting": _ctx.selectedCustomIntegrationSettings,
                      "custom-integration-type": _ctx.selectedCustomIntegrationType,
                      onBack: _ctx.stepBack,
                      onKeyGenerated: _ctx.handleKeyGeneration
                    }, null, 8, ["custom-integration-setting", "custom-integration-type", "onBack", "onKeyGenerated"]))
                  : (_ctx.isCustomIntegrationSelected && _ctx.apiKey)
                    ? (_openBlock(), _createBlock(_component_CopyApiKey, {
                        key: 1,
                        "custom-integration-type": _ctx.selectedCustomIntegrationType,
                        "api-key": _ctx.apiKey
                      }, null, 8, ["custom-integration-type", "api-key"]))
                    : (_openBlock(), _createBlock(_component_select_projects, {
                        key: 2,
                        "selected-trigger": _ctx.selectedTrigger.trigger,
                        "selected-integration": _ctx.selectedIntegration,
                        "automation-name": _ctx.selectedTrigger.automationName,
                        "shopify-order-origin": _ctx.selectedTrigger.shopifyOrderOrigin,
                        "form-impact-source": _ctx.selectedTrigger.formImpactSource,
                        onBack: _ctx.stepBack,
                        onRevertTriggerSelection: _ctx.revertTriggerSelection
                      }, null, 8, ["selected-trigger", "selected-integration", "automation-name", "shopify-order-origin", "form-impact-source", "onBack", "onRevertTriggerSelection"]))
              ]),
              _: 1
            }, 8, ["title", "description"])
          ]),
          _: 1
        }, 8, ["is-visible"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}