<template>
  <div class="api-keygen">
    <div
      class="d-md-inline-flex d-flex justify-md-space-between align-md-center flex-column flex-md-row mb-4"
      style="width: 100%"
    >
      <div class="mb-3 mb-md-0">
        <h2>
          {{ t('title') }}
        </h2>
        <p class="description ma-0">
          {{ t('description_custom_integrations') }}
        </p>
        <i18n-t keypath="ApiKey.help_text" tag="div" class="help-text">
          <a
            :href="API_DOCUMENTATION_URL"
            target="_blank"
            style="text-decoration: none"
            class="font-weight-bold"
          >
            {{ t('help_link') }}
          </a>
        </i18n-t>
      </div>
      <v-btn
        class="text-white"
        height="56"
        min-width="227"
        color="green"
        @click="isCreateApiKeyDialogOpen = true"
      >
        {{ t('generate') }}
      </v-btn>
    </div>
    <LoadingTable v-if="isApiKeysLoading" />
    <v-table v-if="!isApiKeysLoading && apiKeys.length > 0" class="keys table" fixed-header>
      <template v-slot:default>
        <thead>
          <th class="text-left">
            {{ t('table_name') }}
          </th>
          <th class="text-left">
            {{ t('table_type') }}
          </th>
          <th class="text-left">
            {{ t('placeholder') }}
          </th>
          <th class="text-left">
            {{ t('table_created') }}
          </th>
          <th />
        </thead>
        <tbody>
          <tr v-for="(item, index) in apiKeys" :key="index">
            <td>{{ item.name }}</td>
            <td>{{ apiKeyTypeFilter(item.subType) }}</td>
            <td>{{ visibleKeyFilter(item.visibleValue) }}</td>
            <td>{{ dateFormatFilter(item.activationDate, userLocale) }}</td>
            <td>
              <QuickMenu
                :quick-menu-items="menuItems"
                @quickMenuClick="quickMenuClick($event, item)"
              />
            </td>
          </tr>
        </tbody>
      </template>
    </v-table>
    <v-dialog v-model="isCreateApiKeyDialogOpen" persistent width="572" transition="fade">
      <v-card>
        <v-card-title
          class="text-black font-weight-bold text-h5 d-flex py-6 pt-4 pb-3 align-center"
        >
          {{ t('create_key_title') }}
          <v-spacer />
          <v-btn icon @click="resetState" variant="plain">
            <v-icon color="black"> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="text-black text-body-1">
          {{ t('create_key_text') }}
          <i18n-t keypath="ApiKey.help_text" tag="div" class="help-text">
            <a
              :href="API_DOCUMENTATION_URL"
              target="_blank"
              style="text-decoration: none"
              class="font-weight-bold"
            >
              {{ t('help_link') }}
            </a>
          </i18n-t>
        </v-card-text>
        <v-card-text>
          <v-form>
            <v-text-field
              v-model="createName"
              class="mb-5"
              variant="outlined"
              flat
              :label="t('create_key_name')"
              required
              :disabled="!apiFeature"
            />
            <v-select
              v-model="createType"
              :items="keyTypeList"
              :label="t('table_type')"
              variant="outlined"
              flat
              required
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <gs-button
            height="50px"
            full-width
            size="large"
            :loading="isCreateApiKeyLoading"
            :disabled="isCreateButtonDisabled"
            @click="createApiKey"
            class="mx-2 mb-4"
          >
            {{ t('create_key_title') }}
          </gs-button>
        </v-card-actions>
        <v-card-text v-if="!apiFeature" class="text-black text-body-1">
          {{ t('upgrade_text') }}
        </v-card-text>
        <v-card-actions v-if="!apiFeature">
          <gs-button
            height="50px"
            @click="$router.push('/upgrade-plan?tab=apiKeys')"
            class="mx-2 mb-4"
          >
            {{ t('upgrade') }}
          </gs-button>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isYourApiKeyDialogOpen" persistent width="572" transition="fade">
      <v-card>
        <v-card-title
          class="text-black font-weight-bold text-h5 d-flex py-6 pt-4 pb-3 align-center"
        >
          {{ t('your_key_title') }}
          <v-spacer />
          <v-btn icon @click="resetState" variant="plain">
            <v-icon color="black"> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="text-black text-body-1">
          {{ t('your_key_text_1') }}
          <p class="text-red font-weight-bold">
            {{ t('your_key_text_2') }}
          </p>
        </v-card-text>
        <v-card-text>
          <v-form class="d-flex">
            <v-text-field v-model="createdKey" variant="outlined" flat disabled class="pr-2" />
            <v-btn
              class="text-capitalize text-h5 text-black"
              height="56"
              min-width="134"
              :title="t('copy_title')"
              elevation="0"
              variant="outlined"
              style="border-color: #bbbbbb"
              @click="copyToClipboard"
            >
              {{ isCopied ? t('is_copied') : t('copy') }}
            </v-btn>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <gs-button
            height="50px"
            full-width
            type="secondary"
            class="mx-2 mb-4"
            size="large"
            @click="resetState"
          >
            {{ t('close') }}
          </gs-button>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isDeleteApiKeyDialogOpen" persistent width="572" transition="fade">
      <v-card>
        <v-card-title class="text-red font-weight-bold text-h5 d-flex py-6 pt-4 pb-3 align-center">
          {{ t('delete_key_title') }}
          <v-spacer />
          <v-btn icon @click="resetState" variant="plain">
            <v-icon color="black"> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="text-black text-body-1">
          {{ t('delete_key_text') }}
        </v-card-text>
        <v-checkbox v-model="isDeleteApiKeyDialogChecked" class="delete checkbox">
          <template v-slot:label>
            <span id="checkboxLabel" class="text-black">{{ t('delete_key_checkbox') }}</span>
          </template>
        </v-checkbox>
        <v-card-actions class="ga-2">
          <gs-button
            height="50px"
            width="272"
            size="large"
            :loading="isDeleteApiKeyLoading"
            :disabled="isDeleteApiKeyLoading || !isDeleteApiKeyDialogChecked"
            @click="deleteApiKey"
          >
            {{ t('delete_key_title') }}
          </gs-button>
          <gs-button height="50px" width="272" size="large" type="secondary" @click="resetState">
            {{ t('close') }}
          </gs-button>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isEditApiKeyDialogOpen" persistent width="572" transition="fade">
      <v-card height="296">
        <v-card-title
          class="text-black font-weight-bold text-h5 d-flex py-6 pt-4 pb-3 align-center"
        >
          {{ t('edit_key_title') }}
          <v-spacer />
          <v-btn icon @click="resetState" variant="plain">
            <v-icon color="black"> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form class="mt-5">
            <v-text-field
              v-model="editName"
              variant="outlined"
              flat
              :label="t('create_key_name')"
              required
            />
          </v-form>
        </v-card-text>
        <v-card-actions class="mt-1">
          <gs-button
            height="50px"
            :loading="isEditApiKeyLoading"
            :disabled="isEditApiKeyLoading || editName.length < 1 || editName === currentItem?.name"
            size="large"
            full-width
            @click="editApiKey"
          >
            {{ t('save') }}
          </gs-button>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isWarningDialogOpen" width="600" persistent transition="fade">
      <WarningDialog
        v-if="createType"
        :api-key-type="createType"
        @generateApiKey="
          () => {
            isWarningDialogOpen = false
            isCreateApiKeyDialogOpen = true
            createType && generateApiKey(createType)
          }
        "
        @closePopup="
          () => {
            isWarningDialogOpen = false
            isCreateApiKeyDialogOpen = true
          }
        "
      />
    </v-dialog>
    <ApiUsage />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import WarningDialog from '@/components/account/WarningDialog.vue'
import { generateApiKey, updateApiKey, deleteApiKey, getApiKeys } from '@api/index'
import LoadingTable from '@/components/tools/Table/LoadingTable.vue'
import QuickMenu from '@/components/tools/Table/QuickMenu.vue'
import { API_DOCUMENTATION_URL } from '@/helpers/constants'
import ApiUsage from '@/components/account/ApiUsage.vue'

import type { ApiKeyResponse } from '@api/index'
import type { Notification } from '@/store/notification'
import type { ApiKeyType } from '@api/index'

export const API_KEY_TYPE_NAME_MAP = {
  WIDGET_API_KEY: 'Widget API',
  OPEN_API_KEY: 'Greenspark API',
  ZAPIER_KEY: 'Zapier',
  INTEGRATELY_KEY: 'Integrately',
}

export function getApiKeyNameByValue(value: string): string {
  return API_KEY_TYPE_NAME_MAP[value] || ''
}

export default defineComponent({
  name: 'ApiKey',
  components: {
    WarningDialog,
    LoadingTable,
    QuickMenu,
    ApiUsage,
  },
  data() {
    return {
      isApiKeysLoading: false,
      isCreateApiKeyLoading: false,
      isDeleteApiKeyLoading: false,
      isEditApiKeyLoading: false,
      currentItem: undefined,
      apiKeys: [],
      isCopied: false,
      isCreateApiKeyDialogOpen: false,
      isEditApiKeyDialogOpen: false,
      isYourApiKeyDialogOpen: false,
      isDeleteApiKeyDialogOpen: false,
      isDeleteApiKeyDialogChecked: false,
      isWarningDialogOpen: false,
      menuItems: [
        {
          value: 'edit',
          label: 'Edit',
          image: '',
        },
        {
          value: 'delete',
          label: 'Delete',
          image: '',
        },
      ],
      createName: '',
      createType: undefined,
      createdKey: '',
      editName: '',
      API_DOCUMENTATION_URL: '',
      keyTypeList: [
        { title: getApiKeyNameByValue('WIDGET_API_KEY'), value: 'WIDGET_API_KEY' },
        { title: getApiKeyNameByValue('OPEN_API_KEY'), value: 'OPEN_API_KEY' },
        { title: getApiKeyNameByValue('ZAPIER_KEY'), value: 'ZAPIER_KEY' },
        { title: getApiKeyNameByValue('INTEGRATELY_KEY'), value: 'INTEGRATELY_KEY' },
      ],
    } as {
      isApiKeysLoading: boolean
      isCreateApiKeyLoading: boolean
      isDeleteApiKeyLoading: boolean
      isEditApiKeyLoading: boolean
      currentItem?: ApiKeyResponse
      apiKeys: ApiKeyResponse[]
      isCopied: boolean
      isCreateApiKeyDialogOpen: boolean
      isEditApiKeyDialogOpen: boolean
      isYourApiKeyDialogOpen: boolean
      isDeleteApiKeyDialogOpen: boolean
      isDeleteApiKeyDialogChecked: boolean
      isWarningDialogOpen: boolean
      menuItems: {
        value: string
        label: string
        image: string
      }[]
      createName: string
      createType?: ApiKeyType
      createdKey: string
      editName: string
      API_DOCUMENTATION_URL: string
      keyTypeList: { title: ApiKeyType; value: string }[]
    }
  },
  computed: {
    apiFeature(): boolean {
      if (this.createType || this.createType === 'WIDGET_API_KEY') {
        return true
      }

      if (this.createType === 'OPEN_API_KEY') {
        return this.dataAPI
      }

      if (this.createType === 'ZAPIER_KEY' || this.createType === 'INTEGRATELY_KEY') {
        return this.integrators
      }

      return true
    },
    showWarningDialogOnApiKeyCreation(): boolean {
      return Boolean(
        (this.createType === 'WIDGET_API_KEY' &&
          this.apiKeys.find((key) => key.subType === 'WIDGET_API_KEY')) ||
          (this.createType === 'ZAPIER_KEY' &&
            this.apiKeys.find((key) => key.subType === 'ZAPIER_KEY')) ||
          (this.createType === 'INTEGRATELY_KEY' &&
            this.apiKeys.find((key) => key.subType === 'INTEGRATELY_KEY')),
      )
    },
    isCreateButtonDisabled(): boolean {
      return (
        this.isCreateApiKeyLoading ||
        this.createName.length < 1 ||
        (this.createType && this.createType.length < 1) ||
        (!this.dataAPI && this.createType === 'OPEN_API_KEY') ||
        (!this.integrators && this.createType === 'ZAPIER_KEY') ||
        (!this.integrators && this.createType === 'INTEGRATELY_KEY')
      )
    },
    userLocale(): string {
      return this.$store.getters['getUserLocale']
    },
    dataAPI(): boolean {
      return this.$store.getters['getDataAPIFeatureSetting']
    },
    integrators(): boolean {
      return this.$store.getters['getIntegratorsFeatureSetting']
    },
  },
  async created() {
    await this.loadApiKeys()
    this.API_DOCUMENTATION_URL = API_DOCUMENTATION_URL
  },
  methods: {
    dateFormatFilter: function (value, userLocale) {
      if (!value) return ''
      value = value.toString()
      return new Date(value).toLocaleDateString(userLocale || 'en')
    },
    visibleKeyFilter: function (value) {
      if (!value) return ''
      value = value.toString()
      return value + '••••••••••••••••••••••••••••••••••••••'
    },
    apiKeyTypeFilter: getApiKeyNameByValue,
    async loadApiKeys() {
      this.isApiKeysLoading = true
      try {
        const { data } = await getApiKeys()
        this.apiKeys = data
      } catch (e) {
        this.$store.dispatch('notification/notify', {
          text: 'An unexpected error occurred. Please try again or get in touch.',
          isError: true,
        } as Notification)
      } finally {
        this.isApiKeysLoading = false
      }
    },
    t(key: string) {
      return this.$t(`ApiKey.${key}`)
    },
    copyToClipboard() {
      navigator.clipboard.writeText(this.createdKey).then(() => {
        this.isCopied = true
        setTimeout(() => {
          this.isCopied = false
        }, 2000)
      })
    },
    async createApiKey() {
      if (this.showWarningDialogOnApiKeyCreation) {
        this.isCreateApiKeyDialogOpen = false
        this.isWarningDialogOpen = true
      } else {
        if (this.createType) await this.generateApiKey(this.createType)
      }
    },
    async generateApiKey(type: string) {
      this.isCreateApiKeyLoading = true
      try {
        const { data } = await generateApiKey(this.createName, type)
        this.createdKey = data.apiKey
        this.isCreateApiKeyDialogOpen = false
        this.isYourApiKeyDialogOpen = true
        await this.loadApiKeys()
      } catch (e) {
        this.$store.dispatch('notification/notify', {
          text: 'An unexpected error occurred. Please try again or get in touch.',
          isError: true,
        } as Notification)
      } finally {
        this.isCreateApiKeyLoading = false
        this.isApiKeysLoading = false
      }
    },
    async editApiKey() {
      this.isEditApiKeyLoading = true
      try {
        await updateApiKey(this.editName, this.currentItem?._id)
        await this.loadApiKeys()
        await this.resetState()
      } catch (e) {
        this.$store.dispatch('notification/notify', {
          text: 'An unexpected error occurred. Please try again or get in touch.',
          isError: true,
        } as Notification)
      } finally {
        this.isEditApiKeyLoading = false
      }
    },
    async deleteApiKey() {
      this.isDeleteApiKeyLoading = true
      try {
        await deleteApiKey(this.currentItem?._id)
        await this.loadApiKeys()
        await this.resetState()
      } catch (e) {
        this.$store.dispatch('notification/notify', {
          text: 'An unexpected error occurred. Please try again or get in touch.',
          isError: true,
        } as Notification)
      } finally {
        this.isDeleteApiKeyLoading = false
        this.isApiKeysLoading = false
      }
    },
    async quickMenuClick(value: string, item: ApiKeyResponse) {
      switch (value) {
        case 'edit':
          this.currentItem = item
          this.editName = item.name
          this.isEditApiKeyDialogOpen = true
          break
        case 'delete':
          this.currentItem = item
          this.isDeleteApiKeyDialogOpen = true
          break
      }
    },
    async resetState() {
      this.isApiKeysLoading = false
      this.isCreateApiKeyDialogOpen = false
      this.isCreateApiKeyLoading = false
      this.isWarningDialogOpen = false
      this.isYourApiKeyDialogOpen = false
      this.isEditApiKeyDialogOpen = false
      this.isEditApiKeyLoading = false
      this.isDeleteApiKeyDialogOpen = false
      this.isDeleteApiKeyDialogChecked = false
      this.isDeleteApiKeyLoading = false
      this.createName = ''
      this.createType = undefined
      this.createdKey = ''
    },
  },
})
</script>

<style lang="scss" scoped>
.api-input {
  background-color: #3b755f40;
  flex: 1 1 60%;
}

.delete.button {
  position: relative;
  flex: 1 1 50%;
  margin-left: 8px;
  margin-right: 20px;
  margin-bottom: 16px;
}

.create.button {
  position: relative;
  flex: 1 1 100%;
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 16px;
}

.cancel.button {
  position: relative;
  flex: 1 1 50%;
  margin-right: 8px;
  margin-bottom: 16px;
}

.delete.checkbox {
  margin-left: 24px;
}

.keys.table {
  border: 1px solid #bbbbbb;
  border-radius: 10px;
}

.keys.table th {
  padding: 8px 16px;
  background: none !important;
}
</style>
